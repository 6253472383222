import { nameValidator } from '@/utils/validate'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'code',
      type: 'input',
      label: 'profile.code',
      otherOptions: {
        maxlength: 255
      }
    },
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        maxlength: 100
      }
    }
  ],
  validateRules: {
    name: [
      { required: true, message: 'general.required', trigger: 'change' },
      { validator: nameValidator, trigger: 'change' }
    ],
    code: [{ required: true, message: 'general.required', trigger: 'change' }]
  }
}
