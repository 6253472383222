import { getCommonParamsOptions } from '@/utils/common.js'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'code',
      type: 'input',
      label: 'profile.code'
    },
    {
      field: 'name',
      type: 'input',
      label: 'general.name'
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status')
    }
  ]
}
